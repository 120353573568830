const appConfig = {
  "name": "frc-web",
  "version": "1.0.0",
  "stage": "release",
  "regions": [
    {
      "name": "us-east-1",
      "isPrimaryRegion": true,
      "deployThisBuild": true,
      "regionDomain": "get.atlas-config-test.mit.edu",
      "coupaUrl": "https://mit-dev.coupahost.com",
      "showNew": true,
      "amplify": {
        "userPoolId": "us-east-1_nY154N4yV",
        "userPoolWebClientId": "5n162vbs58kdv62a6bbtkttc2s",
        "oauth": {
          "domain": "atlas-auth-test.mit.edu",
          "scope": [
            "profile",
            "openid",
            "digital-id/user",
            "user/finance"
          ],
          "redirectSignIn": "https://frc-test.mit.edu",
          "redirectSignOut": "https://frc-test.mit.edu/logout",
          "responseType": "token",
          "options": {
            "AdvancedSecurityDataCollectionFlag": false
          }
        }
      },
      "signInProvider": "Touchstone",
      "api": {
        "domain": "api-test.mit.edu",
        "paths": {
          "digital-id": "digital-id-v1"
        }
      }
    },
    {
      "name": "us-west-1",
      "isPrimaryRegion": false,
      "deployThisBuild": true,
      "regionDomain": "get.atlas-config-test.mit.edu",
      "amplify": {
        "userPoolId": "us-west-1_KRL6VdoBU",
        "oauth": {
          "domain": "atlas-auth-us-west-test.mit.edu",
          "scope": "",
          "responseType": "token",
          "options": {
            "AdvancedSecurityDataCollectionFlag": false
          }
        }
      },
      "signInProvider": "Touchstone",
      "api": {
        "domain": "api-us-west-test.mit.edu",
        "paths": {
          "digital-id": "digital-id-v1"
        }
      }
    }
  ]
};
export default appConfig;