import { appInitialized } from '@mit/aws-react'
import { useEffect, useState } from 'react'
import { getApiBaseUrl, prepareHeaders } from 'utility/Api'

interface UseAppInitializationReturn {
  initializeApp: () => void
}

export const useAppInitializer = (): UseAppInitializationReturn => {
  const [initializeApi, setInitializeApi] = useState(false)

  /**
   * Use Skip Option to not query api on initial load. Once state flag is flipped to true, queries will load.
   * Use useEffect to trigger when everything you need has loaded and any auth check has passed/not passed
   *
   * Below is just an example. The profile component handles fetching it's own data
   */

  useEffect(() => {
    const fetchData = async (): Promise<any> => {
      const headers = new Headers()
      headers.append('Content-Type', 'application/json')
      const baseUrl = getApiBaseUrl()
      const apiPath = 'frc-v1'
      const response = await fetch(`https://${baseUrl}/${apiPath}/financialPeriods`, {
        method: 'GET',
        headers: await prepareHeaders(headers)
      })
      return await response.json()
    }

    if (initializeApi) {
      fetchData()
        .then((value): any => {
          if (value.row !== undefined) {
            appInitialized({
              isLoading: false,
              hasAccess: true,
              hasError: false
            })
          } else {
            appInitialized({
              isLoading: false,
              hasAccess: false,
              hasError: true
            })
          }
        })
        .catch((e: any): any => {
          console.log(e)
        })
    }
  }, [initializeApi])

  const initializeApp = async (): Promise<void> => {
    setInitializeApi(true)
  }

  return { initializeApp }
}
