export enum Review {
  NONE = 'none',
  DR = 'dReview',
  VR = 'vReview',
  IS = 'IssueIdentified',
  RES = 'Resolved'
}

export interface ItemResultType {
  id: string
  costObjectName: string
  transactionType: string
  docTypeDescription: string
  glAccount: string
  docDate: string
  docNumber: string
  lineItemText: string
  reference: string
  poNumber: string
  coupaPoNumber: string
  transactionDetail: string
  actualAmount: string
  reviewed: any
  reason: any
  risk: string
  period: string
  changedReason?: boolean
  changedComment?: boolean
  changed?: boolean
}

export interface ReviewEntry {
  COST_COLLECTOR_ID: string
  DOCUMENT_NUMBER: string
  DOCUMENT_LINE_ITEM: string
  GL_ACCOUNT_ID: string
  STATUS: string
  CGCOMMENT: string
  REPORT_ID: string
  REPORT_NAME: string
  REASON_CODE: string
}

export interface ReportConfig {
  reportConfig: ReviewEntry[]
}
